<template>
  <section class="content-section">

    <div class="row" v-if="mine && (!$store.state.connected || $store.state.currentNetwork !== 2000)">
      <div class="text-center">
        <span>Please connect to {{ $store.getters.config.chainName }} to make changes.</span><br/>
        <button class="btn btn-primary" v-on:click="$store.dispatch('connectWallet')">Connect wallet</button>
      </div>
    </div>

    <template v-if="!mine || ($store.state.connected && $store.state.currentNetwork == 2000)">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-10 col-lg-8 mx-auto text-center">
          <h2 class="text-white mb-5" v-if="metadata['name'] && mine">Current drip.</h2>
          <h2 class="text-white mb-5" v-if="metadata['name'] && !mine">
            {{ metadata['name'] }}
          </h2>
        </div>
      </div>
    </div>

    <div class="container text-center fitting-room">
      <div class="row" v-if="metadata">
        <div class="col-lg-4 col-md-12 col-sm-12">
          <div>
            <Image v-if="nft.equipment" v-bind:equipment="nft.equipment"></Image>
          </div>
          <div id="nft-name" v-if="mine">
            <span v-if="!metadata.name_revealed">
              <strong>{{ metadata.name }}</strong><br/>
            </span>
            <span v-if="metadata.name_revealed">
              <strong>{{ metadata.name }}</strong>
            </span>
            <button v-if="!metadata.name_revealed" @click="revealName(selectedBody)" class="btn btn-primary">Reveal name</button>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12">
          <div class="container">
            <div class="row">
              <template v-for="slot in slots" :key="slot">
                <div class="col-3 col-md-3 col-sm-3 shelve-tooltip clickable">
                  <template v-if="slotGrid[slot]">
                    <img
                        v-if="$store.getters.config.chainId == 10000"
                        v-bind:src="('https://api.cryptor.at/original/rat/' + slotGrid[slot].id)"
                        @click="slotGrid[slot].selected ? (catalog ? catalogToggle(slotGrid[slot]) : toggle(slotGrid[slot])) : unequip(slotGrid[slot])"
                        v-bind:class="'img-fluid nft ' + (slotGrid[slot].selected?'selected':'')"/>
                    <img
                        v-if="$store.getters.config.chainId == 2000"
                        v-bind:src="('https://api.cryptor.at/nightmares/rat/' + slotGrid[slot].id + '_' + slotGrid[slot].trait_id)"
                        @click="slotGrid[slot].selected ? (catalog ? catalogToggle(slotGrid[slot]) : toggle(slotGrid[slot])) : unequip(slotGrid[slot])"
                        v-bind:class="'img-fluid nft ' + (slotGrid[slot].selected?'selected':'')"
                    />
                  </template>
                  <template v-if="!slotGrid[slot]">
                    <img width="256" @click="filterShelves(slot)" height="256" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8Ww8AAj8BXkQ+xPEAAAAASUVORK5CYII=" class="img-fluid nft"/>
                  </template>
                  {{slot}}
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="col-lg-4 d-none d-lg-block d-xl-block">
          <div class="container text-start">
            <template v-if="mine">
              <router-link v-bind:to="'/rat/id/' + selectedBody" class="btn btn-primary">&laquo; Back</router-link>
              <br/>
              <br/>
            </template>
            <div class="row">
              <h2>{{ $store.getters.config.power }}</h2>
              <strong>Total: {{currentNexus}} {{ $store.getters.config.powerIcon }} <template v-if="newNexus !== currentNexus"> &raquo; {{newNexus}} {{ $store.getters.config.powerIcon }}</template></strong><br/>
              <span>Base: <strong>{{ metadata.basePower }} {{ $store.getters.config.powerIcon }}</strong></span>
              <br/>
              <br/>
              <h2>Base traits:</h2>
              <template v-for="attribute in metadata.attributes" :key="attribute">
                <div v-if="attribute.slot === 0">
                  {{ attribute.trait_type }}: <strong>{{ attribute.name }}</strong> {{ $store.getters.config.powerIcon }} {{ attribute.power }}
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="col-12">
          <button v-if="!catalog && Object.keys(selectedEquip).length > 0" @click="equip" class="btn btn-primary">Dress up! 🧛</button> &nbsp;
          <button v-if="!catalog && Object.keys(selectedEquip).length > 0" @click="selectedEquip = []; preview();" class="btn btn-primary">Unselect all</button> &nbsp;
          <button v-if="catalog && Object.keys(catalogEquip).length > 0" @click="catalogEquip = []; preview();" class="btn btn-primary">Unselect all</button> &nbsp;
          <button v-if="!catalog && mine" @click="setAlpha(selectedBody)" class="btn btn-primary">Set as alpha</button> &nbsp;
          <button v-if="!catalog && mine" @click="unequipAll()" class="btn btn-primary">Unequip all</button> &nbsp;
        </div>

        <div class="col-lg-12 d-lg-none d-xl-none g-4">
          <h2>{{ $store.getters.config.power }}</h2>
          <strong>Total: {{currentNexus}} {{ $store.getters.config.powerIcon }} <template v-if="newNexus !== currentNexus"> &raquo; {{newNexus}} {{ $store.getters.config.powerIcon }}</template></strong>
          <br/>
          <router-link v-bind:to="'/rat/id/' + selectedBody" class="btn btn-primary">&laquo; Back</router-link>
        </div>

        <template v-if="$store.state.connected && mine">

          <div class="col-12 g-4">
            <h2>
              <template v-if="!catalog">My Traits</template>
              <template v-if="catalog">Traits</template>
            </h2>
            <div class="form-check form-switch float-end">
              <input class="form-check-input" type="checkbox" role="switch" id="catalogSwitch" @change="loadCatalog($event.target.checked)">
              <label class="form-check-label" for="catalogSwitch">Catalog mode</label>
            </div>
          </div>
          <template v-if="!catalog">
            <div class="col-12 trait-types">
              <template v-for="slot in slots" :key="slot">
                <button v-bind:class="'btn ' + (filter == slot ? 'btn-primary':'btn-outline-primary')" @click="filterShelves(slot)">{{ slot }}</button> &nbsp;
              </template>
            </div>
            <div class="col-12">
              <div class="row">
                <template v-for="nft in $store.state.wallet" :key="nft">
                  <div class="col-lg-1 col-md-3 col-sm-2 col-2 shelve-tooltip rat clickable" v-if="$store.state.nfts[nft].metadata.slot && $store.state.nfts[nft].metadata.slot != 1 && (filter == null || filter == $store.state.nfts[nft].slot)">
                    <span>
                      <img v-bind:src="$store.state.nfts[nft].metadata.image" v-bind:class="'img-fluid nft ' + (selectedEquip[$store.state.nfts[nft].slot] && selectedEquip[$store.state.nfts[nft].slot].id == $store.state.nfts[nft].id?'selected':'')" @click="toggle($store.state.nfts[nft])" />
                      <div class="bottom-right" @click="toggle($store.state.nfts[nft])">{{ $store.getters.config.powerIcon }} {{ $store.state.nfts[nft].metadata.power }}</div>
                    </span>
                    <div v-if="$store.state.nfts[nft].metadata.trait_id === 165" class="top-left" @click="window.location = '/draw/' + $store.state.nfts[nft].id">✏️</div>
                    <div class="shelve-tooltiptext">
                      <strong>{{ $store.state.nfts[nft].metadata.name }}</strong><br/>
                      {{ $store.state.nfts[nft].slot }} - {{ $store.getters.config.powerIcon }} {{ $store.state.nfts[nft].metadata.power }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <template v-if="catalog">
            <div class="col-12 trait-types">
              <template v-for="slot in slots" :key="slot">
                <button v-bind:class="'btn ' + (filter == slot ? 'btn-primary':'btn-outline-primary')" @click="filterShelves(slot)">{{ slot }}</button> &nbsp;
              </template>
            </div>
            <div class="col-12" v-if="catalogItems.length > 0">
              <div class="row">
                <template v-for="nft in catalogItems" :key="nft">
                  <div class="col-lg-1 col-md-3 col-sm-2 col-2 shelve-tooltip rat clickable" v-if="nft.slot && nft.slot != 1 && (filter == null || filter == nft.slot)">
                    <span>
                      <img v-bind:src="'https://api.cryptor.at/nightmares/rat/99999_' + nft.id" v-bind:class="'img-fluid nft ' + (catalogEquip[nft.slot] && catalogEquip[nft.slot].trait_id == nft.id?'selected':'')" @click="catalogToggle(nft)" />
                      <div class="bottom-right" @click="catalogToggle(nft)">{{ $store.getters.config.powerIcon }} {{ nft.power }}</div>
                    </span>
                    <div class="shelve-tooltiptext">
                      <strong>{{ nft.name }}</strong><br/>
                      {{ nft.slot }} - {{ $store.getters.config.powerIcon }} {{ nft.power }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    </template>
  </section>
</template>


<script>
import Image from "@/components/Rat/Image";
export default {
  name: 'App',
  components: {
    Image
  },
  data() {
    return {
      nfts: [],
      selectedBody: 0,
      selectedEquip: [],
      catalogEquip: [],
      equippedItems: [],
      slots: ['Head', 'Goggles', 'Back', 'Face', 'Mouth', 'Neck', 'Hands', 'Clothing', 'Serum', 'Spray'],
      slotGrid: [],
      previewImage: null,
      filter: null,
      mine: false,
      currentNexus: 0,
      newNexus: 0,
      baseNexus: 0,
      metadata: {},
      nft: {
        equipment: null
      },
      catalog: false,
      catalogItems: []
    }
  },
  watch: {
    '$store.state.address': function() {
      this.$store.dispatch('loadWallet');
    },
    '$store.state.nftContract': function() {
      this.load();
    }
  },
  mounted() {
    this.$store.dispatch('loadWallet');
    this.selectedBody = this.$route.params.id;
    this.load();
  },
  methods: {
    loadCatalog: function(catalogMode) {
      if(catalogMode) {
        this.catalog = true
        fetch('https://api.cryptor.at/nightmares/catalog').then(content => {
          return content.json();
        }).then(data => {
          this.catalogItems = data
          this.preview()
        });
      } else {
        this.catalog = false
        this.preview()
      }
    },
    load: function() {
      if(this.$store.state.nftContract) {
        try {
          this.$store.state.nftContract.ownerOf(this.selectedBody).then(owner => {
            if (owner.toLowerCase() === this.$store.state.address.toLowerCase()) {
              this.mine = true;
            }
          }).catch(e => {
            console.log(e);
          });
          this.$store.state.nftContract.tokenURI(this.selectedBody).then(uri => {
            fetch(uri).then(result => {
              return result.json();
            }).then(metadata => {
              this.metadata = metadata;
              this.currentNexus = metadata.power;
              this.baseNexus = metadata.basePower;
              for (let i in metadata.attributes) {
                if (metadata.attributes[i].id > this.$store.getters.config.baseSupply) {
                  this.equippedItems.push(metadata.attributes[i]);
                }
              }
              this.preview();
            });
          }).catch(e => {
            console.log(e);
            this.preview();
          });
        } catch(e) {
          console.log(e);
        }
      }
    },
    setAlpha: async function(id) {
      this.$store.getters.freshNftContract.setAlpha(id).then((tx) => {
        return tx.wait();
      }).then(() => {
        this.$store.dispatch('loadAlpha', this.$store.state.address);
      });
    },
    revealName: async function(id) {
      let signData = {
        tokenId: id,
        owner: this.$store.state.address
      }
      let msg = JSON.stringify(signData);
      this.$store.state.provider.getSigner().signMessage(msg).then(signedMessage => {
        fetch('https://api.cryptor.at/nightmares/revealName?id=' + id + '&owner=' + this.$store.state.address + '&signature=' + signedMessage).then(content => {
          return content.json();
        }).then(data => {
          this.metadata.name = '🎉 ' + data.name + ' [#' + id + '] 🎉';
          this.metadata.name_revealed = true;
        });
      });
    },
    preview: function () {
      if(this.selectedBody > 0) {
        let equip = this.selectedEquip
        if(this.catalog) {
          equip = this.catalogEquip
        }

        var list = [];
        var newPower = this.baseNexus;
        list[1] = {id: this.selectedBody, power: 0 };
        for(var e in this.equippedItems) {
          list[this.equippedItems[e].slotName] = this.equippedItems[e];
          list[this.equippedItems[e].slotName].equipped = true;
        }
        for(var i in equip) {
          if(equip[i]) {
            list[i] = equip[i];
            list[i].equipped = false;
          }
        }
        this.slotGrid = list;
        var filtered = [];
        for(var k in list) {
          if(list[k].trait_id) {
            filtered.push(list[k].id + '_' + list[k].trait_id);
          } else {
            filtered.push(list[k].id);
          }
          newPower += parseInt(list[k].power);
        }
        this.newNexus = newPower;
        this.previewImage = ('https://api.cryptor.at/' + (this.$store.getters.config.chainId === 2000?'nightmares':'original') + '/rat/' + filtered.join(','));
        this.nft.equipment = filtered.join(',');
      }
    },
    catalogToggle: async function(_item) {
      if(typeof this.catalogEquip[_item.slot] !== 'undefined'
          && (this.catalogEquip[_item.slot].trait_id == _item.id || this.catalogEquip[_item.slot].trait_id == _item.trait_id)) {
        delete this.catalogEquip[_item.slot];
      } else {
        this.catalogEquip[_item.slot] = {
          id: 99999,
          trait_id: _item.id,
          slot: _item.slot,
          power: _item.power,
          selected: true
        };
      }
      this.preview();
    },
    toggle: async function(_item) {
      if(typeof this.selectedEquip[_item.slot] !== 'undefined' && this.selectedEquip[_item.slot].id == _item.id) {
        delete this.selectedEquip[_item.slot];
      } else {
        this.selectedEquip[_item.slot] = {
          id: _item.id,
          trait_id: _item.metadata.trait_id,
          slot: _item.slot,
          power: _item.metadata.power,
          selected: true
        };
      }
      this.preview();
    },
    equip: async function() {
      var list = [];
      for(var i in this.selectedEquip) {
        if(this.selectedEquip[i]) {
          var item = this.selectedEquip[i];
          list.push(item.id);
        }
      }
      await (await this.$store.getters.freshNftContract.equip(this.selectedBody, list)).wait();
      window.location.reload();
    },
    unequip: async function(item) {
      if(!item.slot) {
        return;
      }
      await (await this.$store.getters.freshNftContract.unequip(this.selectedBody, [item.slot])).wait();
      window.location.reload();
    },
    unequipAll: async function() {
      var list = [];
      for(var e in this.equippedItems) {
        list.push(parseInt(this.equippedItems[e].slot));
      }
      await (await this.$store.getters.freshNftContract.unequip(this.selectedBody, list)).wait();
      window.location.reload();
    },
    filterShelves: function(filter) {
      if(this.filter == filter) {
        this.filter = null;
      } else {
        this.filter = filter;
      }
    }
  }
}
</script>